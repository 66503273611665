// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-template-js": () => import("/opt/build/repo/src/templates/ArticleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-comparison-template-js": () => import("/opt/build/repo/src/templates/ComparisonTemplate.js" /* webpackChunkName: "component---src-templates-comparison-template-js" */),
  "component---src-templates-manufacturer-template-js": () => import("/opt/build/repo/src/templates/ManufacturerTemplate.js" /* webpackChunkName: "component---src-templates-manufacturer-template-js" */),
  "component---src-templates-page-template-js": () => import("/opt/build/repo/src/templates/PageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-review-template-js": () => import("/opt/build/repo/src/templates/ReviewTemplate.js" /* webpackChunkName: "component---src-templates-review-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("/opt/build/repo/src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-comparisons-js": () => import("/opt/build/repo/src/pages/comparisons.js" /* webpackChunkName: "component---src-pages-comparisons-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manufacturers-js": () => import("/opt/build/repo/src/pages/manufacturers.js" /* webpackChunkName: "component---src-pages-manufacturers-js" */)
}

